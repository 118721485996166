<template>
  <div id="app">
    <div v-if="signedIn">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("./assets/Inter-Regular.ttf") format("truetype");
}

html, body {
  --bs-body-font-family: 'Inter' !important;
  overflow: visible !important;
  min-width: 350px !important;
}
</style>
<script>
import OIDCClient from './client/oidcClient'

export default {
  name: 'App',
  data() {
    return {
      client: new OIDCClient(),
      signedIn: true
    }
  },
  mounted() {
    this.client.getUser().then(
        user => {
          if (user != null) {
            this.$store.dispatch('login', user).then(
                this.signedIn = this.client.getSignedIn()
            )
          }

        },
        err => {
          console.log(err)
        }
    )
  }
}
</script>