import axios from "axios";
import store from '../store'


const sematoClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {}
})

sematoClient.interceptors.request.use(function (request) {
        const token = store.getters.user.token
        if (token) {
            request.headers.Authorization = token;
        }
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

export default sematoClient
