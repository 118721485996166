import Oidc from 'oidc-client';
import store from '../store'

const oidcIssuer = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore(),
    authority: process.env.VUE_APP_AUTH_URL,
    client_id: process.env.VUE_APP_AUTH_CLIENT,
    redirect_uri: window.location.origin + '/auth/callback.html',
    silent_redirect_uri: window.location.origin + '/auth/silent-renew.html',
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 180,
    post_logout_redirect_uri: window.location.origin,
    response_type: 'id_token token',
    scope: 'openid email profile offline_access',
})

oidcIssuer.events.addAccessTokenExpired(function () {
    console.log('AccessToken Expired, logging out');
    oidcIssuer.signoutRedirect().catch(function (err) {
        console.log(err)
    })
});

oidcIssuer.events.addAccessTokenExpiring(function () {
    // update store with new token to keep communication to semato working
    oidcIssuer.getUser().then(function (user) {
            if (user != null) {
                oidcIssuer.startSilentRenew();
                store.dispatch('login', user)
            }
        }
    )
})

export default class OIDCClient {
    getUser() {
        let self = this
        return new Promise((resolve, reject) => {
            oidcIssuer.getUser().then(function (user) {
                if (user == null) {
                    self.signIn()
                    return resolve(null)
                } else {
                    return resolve(user)
                }
            }).catch(function (err) {
                console.log(err)
                return reject(err)
            });
        })
    }

    getSignedIn() {
        let self = this
        return new Promise((resolve, reject) => {
            oidcIssuer.getUser().then(function (user) {
                if (user == null) {
                    self.signIn()
                    return resolve(false)
                } else {
                    return resolve(true)
                }
            }).catch(function (err) {
                console.log(err)
                return reject(err)
            });
        })
    }

    signIn() {
        oidcIssuer.signinRedirect().catch(function (err) {
            console.log(err)
        })
    }

    signOut() {
        oidcIssuer.signoutRedirect().then(localStorage.clear).catch(function (err) {
            console.log(err)
        })
    }
}