<template>
  <div v-if="authenticated">
    <campus-header/>
    <b-container>
      <!-- Header -->
      <b-row class="mt-4">

        <b-col cols="12" sm="10">
          <div class="d-flex align-items-center">
            <p style="margin-bottom: 0;"> {{ this.fullName }}
              ({{ this.$store.getters.user.userId }}) </p>
          </div>
          <div class="d-flex align-items-center">
            <p style="margin-top: 8px; margin-bottom: 0;"> {{ this.organizationName }} &nbsp;</p>
          </div>
          <b-button v-if="this.$store.getters.user.userType === 2" variant="secondary"
                    style="margin-top: 30px; margin-right: 8px; padding: 9px 15px;"
                    v-on:click="switchUserView()">
            {{ userView === 'person' ? "Zur Referenten-Ansicht wechseln" : "Zur Teilnehmer-Ansicht wechseln" }}
          </b-button>
          <b-button v-if="this.$store.getters.user.admin && this.userView === 'person'" variant="secondary"
                    style="margin-top: 30px; padding: 9px 15px;"
                    v-on:click="switchAdminView()">
            {{ adminView ? "Zur persönlichen Ansicht" : "Zur Organisationsansicht" }}
          </b-button>
        </b-col>
      </b-row>
      <div v-if=adminView class="mt-5">
        <organization-admin-component :organization-name="this.organizationName" :organization-data="organizationData"/>
      </div>
      <div class="mt-5">
        <h2 v-if=adminView>Veranstaltungen</h2>
        <h1 class="section" v-if=!adminView>
          {{
            userView === 'referee' ? 'Meine betreuten Veranstaltungen' :
                'Meine gebuchten Veranstaltungen'
          }}
        </h1>
        <b-accordion>
          <b-accordion-item title="anstehende Veranstaltungen" v-bind:visible="true">
            <b-accordion v-if="getUpcomingEvents(bookingGroups).length > 0">
              <booking-group-component v-for="bookingGroup in getUpcomingEvents(bookingGroups)"
                                       v-bind:key="bookingGroup.eventSeries ? bookingGroup.eventSeries.seriesId : 0"
                                       :user-view="this.userView" :bookingGroup="bookingGroup"/>
            </b-accordion>
            <i v-if="getUpcomingEvents(bookingGroups).length === 0">keine anstehenden Veranstaltungen.</i>
          </b-accordion-item>
          <b-accordion-item title="vergangene Veranstaltungen">
            <b-accordion v-if="getPastEvents(bookingGroups).length > 0">
              <booking-group-component v-for="bookingGroup in getPastEvents(bookingGroups)"
                                       v-bind:key="bookingGroup.eventSeries ? bookingGroup.eventSeries.seriesId : 0"
                                       :user-view="this.userView" :bookingGroup="bookingGroup"/>
            </b-accordion>
            <i v-if="getPastEvents(bookingGroups).length === 0">keine vergangenen Veranstaltungen.</i>
          </b-accordion-item>
        </b-accordion>
      </div>
    </b-container>

  </div>
</template>

<script>
import CampusHeader from "@/components/Header";
import Reamaze from '../client/reamaze';
import BookingGroupComponent from "@/components/BookingGroupComponent.vue";
import OrganizationAdminComponent from "@/components/OrganizationAdminComponent.vue";


export default {
  inject: ['sematoClient'],
  name: 'CampusHome',

  data: () => {
    return {
      bookingGroups: [],
      userView: "person",
      adminView: false,
      eventsShown: "upcoming",
      premium: false,
      premiumExpiry: null,
      organizationName: null,
      fullName: null,
      organizationData: []
    }
  },
  methods: {
    async initReamaze() {
      const reamaze = new Reamaze();
      reamaze.setupReamaze(this.$store.getters.user.userId, this.fullName);
    },
    getTitle(bookingGroup) {
      if (bookingGroup.eventSeries === null) {
        if (bookingGroup.events.length > 0) {
          return bookingGroup.events[0].details[0].title;
        } else {
          return "";
        }
      } else {
        return bookingGroup.eventSeries.seriesTitle;
      }
    },
    isNewSeries(bookingGroup) {
      return bookingGroup.eventSeries && bookingGroup.events.length === 0
    },
    switchUserView() {
      if (this.userView === 'person') {
        this.userView = 'referee';
      } else {
        this.userView = 'person';
      }
      this.adminView = false;
      this.fetchEvents();
      this.fetchUserInfo();
    },


    switchAdminView() {
      this.adminView = !this.adminView
      if (this.adminView) {
        this.fetchOrganizationData();
      }
      this.fetchEvents();
      this.fetchUserInfo();
    },

    fetchUserInfo() {
      this.sematoClient.get(`user/profile/${this.userView}`)
          .then(response => {
            this.organizationName = response.data.organization
            this.fullName = response.data.fullName
          })
    },

    fetchOrganizationData() {
      this.sematoClient.get(`user/organization`)
          .then(response => {
            this.organizationData = response.data;
          })
    },

    fetchEvents() {
      let userType = 'person';
      if (this.adminView) {
        userType = 'organization';
      } else if (this.$store.getters.user.userType === 1) {
        this.userView = 'referee'
        userType = 'referee'
      } else {
        userType = this.userView
      }
      this.sematoClient.get(`user/bookings/${userType}`)
          .then(response => {
            this.bookingGroups = response.data
          })
    },
    getUpcomingEvents(bookingGroups) {
      return bookingGroups.filter(bookingGroup => {
        return !bookingGroup.seriesDone;
      }).map(bookingGroup => {
        let upcomingEvents;
        if (bookingGroup.eventSeries && !bookingGroup.seriesDone) {
          upcomingEvents = bookingGroup.events;
        } else {
          upcomingEvents = bookingGroup.events.filter(event => {
            return event.dates.some(date => date.endTimestamp >= Date.now());
          });
        }
        return {
          ...bookingGroup,
          events: upcomingEvents.sort((a, b) => a.dates[0].startTimestamp - b.dates[0].startTimestamp)
        };
      }).filter(bookingGroup => bookingGroup.events.length !== 0 || this.isNewSeries(bookingGroup));
    },
    getPastEvents(bookingGroups) {
      return bookingGroups.filter(bookingGroup => {
        if (bookingGroup.eventSeries) {
          return bookingGroup.seriesDone;
        } else {
          return true;
        }
      }).map(bookingGroup => {
        let pastEvents;
        if (bookingGroup.eventSeries) {
          pastEvents = bookingGroup.events;
        } else {
          pastEvents = bookingGroup.events.filter(event => {
            return event.dates.every(date => date.endTimestamp < Date.now());
          });
        }
        return {
          ...bookingGroup,
          events: pastEvents.sort((a, b) => (a.dates[0] === undefined || b.dates[0] === undefined) ? 0 : new Date(b.dates[0].endTimestamp) - new Date(a.dates[0].endTimestamp))
        };
      }).filter(bookingGroup => bookingGroup.events.length !== 0);
    },
  },

  timers: {
    fetchEvents: {time: 30000, autostart: true, repeat: true}
  },

  computed: {
    authenticated() {
      return this.$store.getters.user.token !== null
    },
  },

  created() {
    this.fetchEvents();
    this.fetchUserInfo();
    this.initReamaze();
  },

  components: {
    OrganizationAdminComponent,
    BookingGroupComponent,
    CampusHeader
  }
}

</script>
