import {createWebHistory, createRouter} from "vue-router";
import CampusHome from '../views/Home.vue'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: CampusHome
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router
