import {createStore} from 'vuex'

export default createStore({

    state: {
        token: null,
        userId: null,
        userViewname: null,
        userType: null,
        webinarUrl: null,
        eventId: null,
        premium: null,
        premiumExpiry: null,
        admin: null
    },

    mutations: {

        authUser(state, userData) {
            state.token = userData.token
            state.userId = userData.userId
            state.userViewname = userData.userViewname
            state.userType = userData.userType
            state.premium = userData.premium
            state.premiumExpiry = userData.premiumExpiry
            state.admin = userData.admin
        },

        webinarUrl(state, data) {
            state.webinarUrl = data.url
            localStorage.setItem('webinarUrl', data.url);
        },

        eventId(state, data) {
            state.eventId = data.eventId
            localStorage.setItem('eventId', data.eventId);
        }

    },

    actions: {
        login({commit}, userDetails) {
            let numberUserType = 0
            if (userDetails.profile.roles.includes('Person') && userDetails.profile.roles.includes('Referee')) {
                numberUserType = 2
            } else if (userDetails.profile.roles.includes('Referee')) {
                numberUserType = 1
            }
            commit('authUser', {
                token: 'Bearer ' + userDetails.access_token,
                userId: userDetails.profile.email,
                userViewname: userDetails.profile.name,
                userType: numberUserType,
                premium: Boolean(userDetails.profile.roles.includes('Learnworlds')),
                premiumExpiry: null,
                admin: Boolean(userDetails.profile.roles.includes('Organization_Admin'))
            });
        },

        loadWebinar({commit}) {
            const webinarUrl = localStorage.getItem('webinarUrl');
            const eventId = localStorage.getItem('eventId');
            commit('webinarUrl', {
                url: webinarUrl
            });
            commit('eventId', {
                eventId: eventId
            });
        },

        webinarUrl({commit}, url) {
            commit('webinarUrl', {
                url: url
            });
        },

        eventId({commit}, eventId) {
            commit('eventId', {
                eventId: eventId
            });
        },
    },

    getters: {

        // this is used by the UI to check login status and to show the appropriate views
        isAuthenticated(state) {
            return state.token !== null
        },

        user(state) {
            return {
                token: state.token,
                userId: state.userId,
                userViewname: state.userViewname,
                userType: Number(state.userType),
                premium: Boolean(state.premium),
                premiumExpiry: new Date(state.premiumExpiry),
                admin: Boolean(state.admin)
            }
        },

        webinarUrl(state) {
            return state.webinarUrl
        },

        eventId(state) {
            return state.eventId
        }
    },

    modules: {}
})

