<template>
  <h1 class="section">Meine Organisation</h1>
  <hr/>
  <h2>Überblick</h2>
  <table class="mt-5">
    <thead>
    <tr>
      <th>Zeitraum</th>
      <th>gebuchte Unterrichtseinheiten</th>
      <th>absolvierte Unterrichtseinheiten</th>
      <th>Zertifikat</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in organizationData" :key="item.startYear">
      <td>{{ item.startYear }}/{{ item.endYear.toString().slice(-2) }}</td>
      <td>{{ item.booked }}</td>
      <td>{{ item.completed }}</td>
      <td>
        <b-button v-if="item.completed > 0 && isBeforeAugust(item.endYear)" variant="link" class="dlBtn"
                  v-on:click="downloadCertificate('organization', item.startYear, item.endYear, this.organizationName)">
          <i class="glyphicon glyphicon-download-alt"></i>
          Download
        </b-button>
        <div v-else>---</div>
      </td>
    </tr>
    </tbody>
  </table>
  <hr/>
</template>
<script>
export default {
  props: ['organizationData','organizationName'],
  inject: ['sematoClient'],
  methods: {
    downloadCertificate(userType, startYear, endYear, fileName) {
      this.sematoClient
          .get(
              `user/${userType}/download/certificate?startYear=${startYear}&endYear=${endYear}`,
              {responseType: "arraybuffer"}
          )
          .then(response => {
            // Create a blob from the response data
            const blob = new Blob([response.data], {type: "application/pdf"});

            // Create a Blob URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create an anchor element for the download link
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = fileName.replace(/\s+/g, '_').toLowerCase() + "_" + startYear + "_" + endYear;

            // Trigger a click event on the link to initiate the download
            link.dispatchEvent(new MouseEvent("click"));

            // Clean up after the download
            setTimeout(() => {
              window.URL.revokeObjectURL(blobUrl);
            }, 100);
          })
    },
    isBeforeAugust(endYear) {
      const augustDate = new Date(`${endYear}-08-01`);
      const currentDate = new Date();
      return augustDate < currentDate;
    }
  }
}
</script>