<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <!-- Display module data -->
      <b-card>
        <b-card-header class="section">
          <b>Bist Du sicher, dass Du dieses Seminar verbindlich buchen möchtest?</b>
        </b-card-header>
        <div style="padding: 15px">
          <div class="section">
            <h4>{{ module.title }}</h4>
            <div>
              <span class="badge badge-informational" style="margin-right: 10px;">{{ module.contentType }}</span>
              <span class="badge badge-informational" style="margin-right: 10px;">{{ module.distributionType }}</span>
              <span class="badge badge-informational" style="margin-right: 10px;">{{ option.lessons }} UE</span>
              <span class="badge badge-informational" style="margin-right: 10px;">{{ module.priority }}</span>
            </div>
          </div>
          <p/>
          Termine:
          <div v-for="date in option.dates" v-bind:key="date.start">
            {{ date.location }}, {{ date.start }} - {{ date.end }}
          </div>
        </div>
      </b-card>
      <div class="alert alert-warning" role="alert" v-if="this.errorMessage">
        {{ this.errorMessage }}
      </div>

      <div class="button-container">
        <button class="cancel-button" @click.stop="closeModal">Abbrechen</button>
        <button class="confirm-button" @click.stop="bookModule">Ja</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['sematoClient'],
  data() {
    return {
      errorMessage: null
    }
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    option: {
      type: Object,
      required: true
    },
    seriesBookingId: {
      type: Number,
      required: true
    }
  },
  methods: {
    bookModule() {
      this.sematoClient.put('booking/event', {
        seriesBookingId: this.seriesBookingId,
        eventId: this.option.id,
      })
          .then((response) => {
            if (response.status === 200) {
              location.reload();
              this.closeModal();
            } else {
              this.errorMessage = response.data;
            }
          }).catch(reason => {
        this.errorMessage = reason.response.data;
      })
    },
    closeModal() {
      // Emit event to notify parent component to close the modal
      this.$emit("close");
    }
  }
};
</script>