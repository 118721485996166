export default class Reamaze {
    setupReamaze(userEmail, userName) {
        const reamazeScript = document.createElement('script');
        reamazeScript.type = 'text/javascript';
        reamazeScript.async = true;
        reamazeScript.src = 'https://cdn.reamaze.io/assets/reamaze.js';
        document.head.appendChild(reamazeScript);

        const customSupport = {
            'account': 'padiko-akademie',
            'ui': {
                'contactMode': 'default',
                'enableKb': true,
                'styles': {
                    'widgetColor': 'rgb(117, 166, 165)',
                    'gradient': true,
                },
                'shoutboxFacesMode': 'default',
                'shoutboxHeaderLogo': true,
                'widget': {
                    'displayOn': 'all',
                    'fontSize': 'default',
                    'allowBotProcessing': true,
                    'label': {
                        'text': 'Hi! Wenn Du Fragen hast, stehen wir gerne für Dich zur Verfügung. 😊',
                        'mode': 'notification',
                        'delay': 5,
                        'duration': 30,
                        'primary': 'Ich habe eine Frage',
                        'secondary': 'Chat schließen',
                        'sound': true,
                    },
                    'position': 'bottom-right',
                    'mobilePosition': 'bottom-right'
                },
            },
            'apps': {
                'faq': {'enabled': true},
                'recentConversations': {'header': 'Letzte Unterhaltungen'},
                'orders': {},
            },
            'user': {
                'email': userEmail,
                'name': userName
            }
        };

        // Merge customSupport with the existing _support object
        const mergedSupport = Object.assign({}, window._support || {}, customSupport);

        // Assign the merged _support object to the global window object
        window._support = mergedSupport;
    }
}