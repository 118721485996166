import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/accordion.css'
import './assets/style.css'
import VueTimers from 'vue-timers'
import sematoClient from './client/sematoClient.js'

const app = createApp(App)

app.use(VueTimers)
app.use(router)
app.use(store)

app.use(BootstrapVue3)


app.config.productionTip = false

app.provide('sematoClient', sematoClient)

app.mount("#app");


